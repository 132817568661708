.asset-discription-ellipsis {
	max-width: 20em;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.message-center {
	margin-left: 35%;
}

.span-datebox {
	em {
		height: 15px !important;
	}
}
.margin-label{
    margin-left: 46px;
}
.margin-span-second{
    margin-left: 32%;
}
.margin-span-first{
    margin-left: 23px;
}
.text-center{
	text-align:center;
}
.asset-container {
		overflow: auto;
		padding: 0 20px 0 0;
		width: 100%;
		.fluid-form {
			h2,
			h3,
			h4 {
				margin-left: 10px;
				margin-right: 10px;
			}
			div.fluid-one-line-checkbox {
				display: flex;
				flex-direction: row;
				margin-left: 0px;
				label {
                    padding-left: 0;
                    margin-right: 0;
                    width: auto;
                    min-width: 0px;
                    margin-left: 0px;
                }
				em:nth-child(3) {
					position: absolute;
					top: 16px;
					right: 25px;
				}
				em:nth-child(2) {
					top: -5px;
				}
				b+em:nth-child(2) {
					position: relative;
					top: 0px;
					right: 2px;
				}
				b+em:nth-child(3) {
					position: relative;
					top: 0px;
					right: 25px;
				}
				&>div:nth-child(1) {
					margin-right: 39.5%;
				}
			}
			div.fluid-one-line {
				//used for radios and checkboxes
				display: flex;
				width: 95%;
				flex: 1 1 auto;
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
				flex-wrap: nowrap;
				>div:not(.fluid-one-line) {
					width: 98%;
					flex-wrap: wrap;
					display: flex;
					flex: 1 1 auto;
					align-items: flex-start;
					margin-top: -5px;
					input[type="radio"],
					input[type="checkbox"] {
						margin: .3em 5px 0 0;
					}
					textarea {
                        width: 98.5%;
                        max-width: 98.5%;
                        }
					label {
						padding: 0 0 0 5px;
					}
				}
				div>textarea {
                        width: 98.5%;
                        max-width: 98.5%;
                        }
			}
			div.fluid-one-line-note {
				//used for radios and checkboxes
				display: flex;
				width: 95%;
				flex: 1 1 auto;
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
				flex-wrap: nowrap;
				>div:not(.fluid-one-line-note) {
					width: 94%;
					flex-wrap: wrap;
					display: flex;
					flex: 1 1 auto;
					align-items: flex-start;
					margin-top: -5px;
					margin-left: 30px;
					input[type="radio"],
					input[type="checkbox"] {
						margin: .3em 5px 0 0;
					}
					textarea {
                        width: 98%;
                        max-width: 98%;
                        }
					label {
						padding: 0 0 0 5px;
					}
				}
			}
			&>div {
				//fluid rows
				display: flex;
				align-items: flex-start;
				&>div {
					//fluid row blocks
					display: flex;
					flex-direction: column;
					input[type='checkbox'] {
						flex-direction: row;
					}
					flex-grow: 1;
					flex-basis: 25%;
					position: relative;
					label {}
					em:nth-child(3) {
						position: absolute;
						top: 16px;
						right: -5px;
					}
					em:nth-child(2) {
						top: -5px;
					}
					b+em:nth-child(2) {
						position: relative;
						top: 0px;
						right: 2px;
					}
					b+em:nth-child(3) {
						position: relative;
						top: 0px;
						right: 25px;
					}
					&>div {
						em {
							position: relative;
							top: -3px;
							right: 3px;
						}
					}
				}
			}
			div>div>fieldset {
				div {
					background-color: #fff;
					display: flex;
				}
				div>div> {
					background-color: #fff;
					display: block;
					flex-grow: 1;
				}
			}
			input[type='text'] {
				line-height: 1.5em;
			}
			input[type='checkbox'] {
				line-height: 1.5em;
				margin-left: 0px;
			}
			input[class='hasDatepicker'] {
				width: 8em;
			}
			input+em,
			select+em,
			textarea+em,
			b+em,
			.ui-datepicker-append+em,
			.ui-datepicker-trigger+em {
				font-size: 150%;
				margin-left: 5px;
				vertical-align: top;
			}

			.error-message {
				margin: 0;
			}
		}
		.fluid-form .autocomplete-combobox-container>span {
			width: ~"calc(92% - 1px)"!important;
			margin: 0 0 10px 5px;
		}
		.fluid-form>div:not(.button-row)>div>div>textarea {
			width: auto;
		}
		.fluid-form>div:not(.button-row)>div>div>input[type=text] {
			width: auto;
		}
		.fluid-form>div:not(.button-row)>div {
            div>select{
                height: 32px;
            	line-height: 32px;
                width: auto;
                width: 95%;
            }
        }
		.fluid-form>div:not(.button-row):nth-of-type(odd):not(.button-row) {
			background-color: #fff;
		}
		.fluid-form .one-row {
			flex-basis: 100%;
		}
		.fluid-form .one-row-width {
			margin-right: 0;
			margin-top: 5px;
			width: 100%;
			textarea {
				width: auto;
				width: ~"calc(100% - 10px)";
			}
			textarea.required+em {
				font-size: 150%;
				margin-left: -1px;
				vertical-align: top;
				top: 0;
				right: -4px;
				position: relative;
			}
			label {
				padding: 0;
			}
		}
		.fluid-form>div.fluid-one-line>h4 {
			width: 100%
		}
		span.fluid-form {
			margin-left: 20px;
			margin-right: 20px;
		}
		.healthplan-container-hide {
			visibility: hidden;
		}
		.fluid-form .autocomplete-combobox-container .angucomplete-row {
			height: auto;
			min-height: 1em;
		}
}

.pds-button-contents {
	text-align:center;
  button {
    width: 25px;
    height: 25px;
	 .delete-minus-icon {
		  margin: 1px 0px 1px -1px;
	  }
	  .basket-icon{
		  margin-top: -1px;
	  }
    pds-icon {
      fill: #ffffff;
      flex: 0 0 auto;
      width: 18px;
      height: 20px;
      margin-left: -2.2px;
    }
    &:hover,
    &:focus,
    button:focus,
    a:focus {
      outline: none;
      border: none;
      cursor: pointer;
      pds-icon {
        svg {
          z-index: 1;
          fill: #ffff;
        }
      }
    }
  }
}
